import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MdbModalRef, MdbModalService } from 'mdb-angular-ui-kit/modal';
import { SharedService } from 'src/app/shared-component/shared.service';
import { BookingService } from '../booking.service';
import { ModalAddonsComponent } from './modal-addons/modal-addons.component';
import { ModalIsAddonAddedComponent } from './modal-is-addon-added/modal-is-addon-added.component';
import { TrackingService } from '../tracking.service';
import { firstValueFrom, forkJoin, Subscription, take } from 'rxjs';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { NotificationService } from 'src/app/notification.service';
import { DisadleContinueService } from 'src/app/disadle-continue.service';


@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.scss']
})
export class ServicesComponent implements OnInit {
  
  disableContinue = false;
  subscription: Subscription = new Subscription;
  tabs: {
    timeFilters: string;
    guest: any;
    service: string;
  } = {
      timeFilters: '30',
      guest: 'me',
      service: '30'
    }
  resetServiceTabs: any;
  resetTimeTabs: any;
  serviceFilter: string = 'Facials 30 Minutes'
  cart: any;
  isSameServices: any = false;
  isGuestTabVisible: boolean = false;
  addonModalRef!: MdbModalRef<ModalAddonsComponent> | null;
  isAddonAddedModalRef!: MdbModalRef<ModalIsAddonAddedComponent> | null;
  modalConfig: any = {
    animation: true,
    backdrop: true,
    containerClass: "right",
    data: {},
    ignoreBackdropClick: false,
    keyboard: true,
    modalClass: "modal-top-right",
  };

  cartSubscription1: any;

  constructor(private trackingService: TrackingService, public bookingService: BookingService, 
    private disadleContinueService:DisadleContinueService,public sharedService: SharedService, private router: Router, private modalService: MdbModalService) {
    bookingService.updateCartDetail();
    this.cartSubscription1 = bookingService.clientCart$
    this.cartSubscription1.subscribe(async (cart: any) => {
      if (cart) {
        this.cart = cart;
        this.getMemberAddedServiceCount;
        let offerId = this.cart?.offers[0]?.id;
        if(offerId){
          const resRemoveCartOffer:any = await firstValueFrom(this.bookingService.removeCartOffer(offerId));
          if(!resRemoveCartOffer.errors){
            this.bookingService.getCartDetail();
            this.sharedService.removeLocalStorageItem('promoCode');
          }
        }
      }
    })
    const serviceName = this.sharedService.getLocalStorageItem('serviceName');
    if (serviceName !== null && serviceName !== undefined) {
    this.serviceAddRequested();
    }
  }

  async ngOnInit(): Promise<void> {
    this.subscription = this.disadleContinueService.disableContinue$.subscribe(value => {
      this.disableContinue = value;
    });
  }
  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
  ngAfterViewInit() {
    setTimeout(() => {
      this.isSameServiceLocal();
    }, 1500);
  }

  // ngDestroy(){
  //   this.cartSubscription1.unsubscribe();
  // }

  serviceAddRequested() {
    const serviceName = this.sharedService.getLocalStorageItem('serviceName');
    if (serviceName && this.cart && this.cart.id) {
        console.log("inside if condition serviceName");
        const service = this.sharedService.findServiceIdByName(serviceName, this.cart);
        this.sharedService.removeLocalStorageItem('serviceName'); // Clear after use

        if (service) {
            this.addService(service);
            this.bookingService.updateCartDetail();
            setTimeout(() => {
              this.bookingService.clientCart$.pipe(take(1)).subscribe(() => {
                  console.log("Navigating after update");
                  this.checkAddonsAndNavigate();
              });
          }, 3000); 
        }
    }
}

checkAddonsAndNavigate() {
  setTimeout(() => {
      let cartMemberCount = this.cart.guests.length + 1;
      if (this.cart.selectedItems.length) {
          if (this.getGroupedServices.length === cartMemberCount) {
              const isAddonsAvailable = this.cart.selectedItems.some((item: any) => item.addons.length > 0);

              // Navigate based on addons availability
              isAddonsAvailable
                  ? this.router.navigateByUrl('/booking/add-ons')
                  : this.router.navigateByUrl('/booking/schedule');
          }
      }
  }, 1500); // Delay as required
}


  isSameServiceLocal() {
    let flag = this.sharedService.getLocalStorageItem('isSameService');
    //console.log("flag",flag);
    if (!flag || flag == 'true') {
      this.isSameServices = true;
      this.isGuestTabVisible = false;
    } else if (flag == 'false') {
      this.isSameServices = false;
      this.isGuestTabVisible = true;
    }
  }

  showAddons() {
    this.tabs.service = 'addon';
    this.changeServiceTab('addon');
    this.resetServiceTabs = { event: true, currentTab: 'addon' };
    setTimeout(() => {
      this.resetServiceTabs = { event: false, currentTab: 'addon' };
    }, 1000);
  }

  changeServiceTab(ev: any) {
    console.log("EV");
    if (ev == '30') {
      this.tabs.service = '30';
    } else if (ev != 'addon') {
      this.tabs.service = ev;
    } else if (ev == 'addon') {
      let selectedItems;
      if (this.tabs.guest != 'me') {
        selectedItems = this.cart.selectedItems.filter((item: any) => item.guestId == this.tabs.guest.id);
      } else {
      
        selectedItems = this.cart.selectedItems.filter((item: any) => item.guestId == null);
      }
      console.log("selectedItems",selectedItems);
      if (selectedItems.length) {
        this.tabs.service = 'addon';
      } else {
        this.resetServicesTab();
        const title = 'Service not added';
        const message = 'Please add a service first';
        this.sharedService.showNotification(title, message);
      }
    }
  }

  resetServicesTab() {
    let currentTab: string = '';
    if (this.tabs.service.toLowerCase().includes('facial')) {
      currentTab = 'facial';
      this.resetTimeTab();
    } else if (this.tabs.service == 'addon') {
      currentTab = 'facial';
      this.resetTimeTab();
    } else {
      currentTab = this.tabs.service;
    }
    this.resetServiceTabs = { event: true, currentTab: currentTab };
    setTimeout(() => {
      this.resetServiceTabs = { event: false, currentTab: currentTab };
    }, 1000);
  }

  resetTimeTab() {
    this.resetTimeTabs = '30';
    setTimeout(() => {
      this.resetTimeTabs = null;
    }, 1000);
  }

  changeGuestTab(ev: any) {
    if (this.tabs.service == 'addon') {
      let selectedItems;
      if (ev != 'me') {
        selectedItems = this.cart.selectedItems.filter((item: any) => item.guestId == ev.id);
      } else {
        selectedItems = this.cart.selectedItems.filter((item: any) => item.guestId == null);
      }
      if (selectedItems && selectedItems.length) {
        this.tabs.guest = ev;
      } else {
        this.resetServicesTab();
        const title = 'Service not added';
        const message = 'Please add a service first';
        this.sharedService.showNotification(title, message);
      }
    }
    this.tabs.guest = ev;
  }

  changeTimeFilterTab(ev: any) {
    this.tabs.timeFilters = ev;
    if (ev == '30') {
      this.tabs.service = 'Facials 30 Minutes'
    } else if (ev == '50') {
      this.tabs.service = 'Facials 50 Minutes'
    }
  }

  async addService(service: any) {
    try {
      if (!service.selected) {
        let canBook = true;
        let selected = this.checkIfServiceSelected(service);

        console.log("issameservices :: ", this.isSameServices);
        if (service.name.toLowerCase().includes('hydrafacial') && this.isSameServices && this.cart.guests.length) {
          canBook = false;
          const title = 'Please select another service.';
          const message = 'Unfortunately, this service cannot be booked for more than one guest at a time.';
          return this.sharedService.showNotification(title, message);
        } else {
          this.cart.selectedItems.map((selectedItem: any) => {
            if (service.name.toLowerCase().includes('hydrafacial') && selectedItem.item.name.toLowerCase().includes('hydrafacial')) {
              canBook = false;
              const title = 'Please select another service.';
              const message = 'Unfortunately, this service cannot be booked for more than one guest at a time.';
              return this.sharedService.showNotification(title, message);
            }
          })
        }

        // if (service.name.toLowerCase().includes('hydrafacial') && selectedItem.item.name.toLowerCase().includes('hydrafacial') && this.isSameServices != 'false') {
        //   console.log("value true");
        //   canBook = false;
        //   this.showNotification(
        //     'Please select another service.',
        //     'Unfortunately, this service cannot be booked for more than one guest at a time.'
        //   );
        //   return;
        // }else{
        //   console.log("value flase");
        // }

        if (!selected && canBook) {
          this.bookingService.ngxUiLoaderService.start(); // Start the loader

          try {
            // Await service selection process
            await this.processServiceSelection(service);

            // Await cart update process
            await this.updateCartDetail();
          } catch (error) {
            console.error('Error during service selection or cart update:', error);
          } finally {
            // Ensure the loader stops regardless of success or failure
            this.bookingService.ngxUiLoaderService.stop();
          }
        } else if (selected && canBook) {
          this.bookingService.ngxUiLoaderService.start();
          try {
            await this.replaceExistingService(service);

            // await new Promise(resolve => setTimeout(resolve, 200)); // 200 milliseconds delay
            await this.updateCartDetail();
          }
          catch (error) {
            console.error('Error during service selection or cart update:', error);
          }
          finally {
            this.bookingService.ngxUiLoaderService.stop();
          }
        } else {
          this.showNotification(
            'Facials, Eyebrows, Microchanneling, Chemical Peels and Injectables cannot be performed together',
            'Please remove existing service from cart'
          );
        }
      } else {
        this.bookingService.ngxUiLoaderService.start();
        await this.removeServiceFromCart();
        this.bookingService.ngxUiLoaderService.stop();
      }
    } catch (error) {
      this.bookingService.ngxUiLoaderService.stop();
      console.error('Error in addService:', error);
      this.showNotification('Error', 'An error occurred while adding the service.');
    }

  }

  checkIfServiceSelected(service: any): boolean {
    return this.cart.selectedItems.some((selectedItem: any) => {
      if (this.tabs.guest !== 'me') {
        return selectedItem.guestId === this.tabs.guest.id;
      } else {
        return selectedItem.guestId === null;
      }
    });
  }

  async processServiceSelection(service: any): Promise<void> {
    //this.bookingService.ngxUiLoaderService.start();
    const payload = {
      id: service.id,
      staffId: null,
      guestId: this.tabs.guest !== 'me' ? this.tabs.guest.id : null,
    };

    try {
      const res: any = await firstValueFrom(this.bookingService.addItemInCart(payload));
      if (!res.errors) {
        service.category = this.tabs.service;
        this.trackingService.addItem(service);
        await this.updateCartDetail();
        await this.copyItemsToGuest();
        await this.updateCartDetail();



        // this.bookingService.activeLocationFilterByService(service.id);
      } else {
        this.showNotification('Errors', res.errors[0].message);
      }
    } finally {
      this.bookingService.ngxUiLoaderService.stop();
    }
  }

  async replaceExistingService(service: any): Promise<void> {

    await this.removeItemsFromCart();
    await this.processServiceSelection(service);
  }

  async updateCartDetail(): Promise<void> {
    //console.log("update cart detail");
    const res: any = await firstValueFrom(this.bookingService.getCartDetail());
    if (res.errors) {
      this.showNotification('Error', 'Error getting cart detail.');
    } else {
      this.bookingService.clientCart$.next(res.data.cart);
    }
  }

  async removeServiceFromCart(): Promise<void> {
    await this.removeItemsFromCart();
    const res: any = await firstValueFrom(this.bookingService.getCartDetail());
    if (!res.errors) {
      this.bookingService.clientCart$.next(res.data.cart);
    }
    this.bookingService.ngxUiLoaderService.stop();
  }

  showNotification(title: string, message: string): void {
    this.sharedService.showNotification(title, message);
  }

  async removeItemsFromCart(): Promise<void> {
    let isSameService = this.sharedService.getLocalStorageItem('isSameService');
    // this.bookingService.ngxUiLoaderService.start();

    try {
      if (isSameService == 'false') {

        for (const item of this.cart.selectedItems) {
          if (this.tabs.guest === 'me') {
            const idsWithNullGuest = this.cart.selectedItems
              .filter((service: { guest: null; }) => service.guest === null)
              .map((service: { id: any; }) => service.id);
            if (idsWithNullGuest.length > 0) {
              await this.bookingService.removeItemInCart(idsWithNullGuest[0]).toPromise();
            }
          } else if (item.guestId === this.tabs.guest.id) {
            await this.bookingService.removeItemInCart(item.id).toPromise();
          }
        }
      } else {
        if (this.cart.selectedItems.length > 0) {
          const removalPromises = this.cart.selectedItems.map((item: { id: string }) =>
            this.bookingService.removeItemInCart(item.id).toPromise());
          await Promise.all(removalPromises);
        }
      }
    } catch (error) {
      console.error("Error removing items:", error);
      this.sharedService.showNotification('Error', "Error removing item from cart");
    } /* finally {
      this.bookingService.ngxUiLoaderService.stop();
    } */
  }


  async removeItemsFromCartOncheck() {
    if (this.cart.selectedItems.length > 0) {
      try {
        // Create the removal promises array once
        let removalPromises: any = [];
        this.cart.selectedItems.map((item: any) => {
          if (item.guestId != null) {
            removalPromises.push(this.bookingService.removeItemInCart(item.id));
          }
        });

        // Await all removal promises at once
        forkJoin(removalPromises).subscribe(() => {
          const meServices = this.cart.selectedItems.filter((item: any) => item.guestId != null);
          meServices.length ? this.bookingService.updateCartDetail() : null;
        });
      } catch (error) {
        console.error("Error removing items:", error);
        this.sharedService.showNotification('Error', "Removing item from cart");
      } finally {
        this.bookingService.ngxUiLoaderService.stop();
      }
    }

  }


  itemRemovedFromCart() {
    // runs when item is removed from cart
    this.isSameServiceLocal();
    setTimeout(() => {
      this.resetServiceTabs = { event: true, currentTab: 'facial' };
    }, 1000);
  }

  get getMemberAddedServiceCount() {
    // let count = 0;
    if (this.cart.guests && this.cart.guests?.length) {
      this.cart.guests.map((guest: any) => {
        if (this.cart && this.cart.selectedItems && this.cart.selectedItems.length) {
          let selectedItems = this.cart.selectedItems.filter((selectedItem: any) => {
            return selectedItem.guestId == guest.id
          });
          guest.addedServiceCount = selectedItems.length;
        }
      });
      let meAsGuest = { "label": "me", "addedServiceCount": 0 };
      let selectedItems = this.cart.selectedItems.filter((selectedItem: any) => {
        return selectedItem.guestId == null
      });
      meAsGuest.addedServiceCount = selectedItems.length;
      return [...this.cart.guests, meAsGuest];
    }
    return [];
  }

  get getTotalAddedServiceCount() {
    let count = 0;
    if (this.cart && this.cart.selectedItems) {
      count = this.cart.selectedItems.length;
    }
    return count;
  }

  hideGuestTabs() {
    this.isGuestTabVisible = false;
  }

  groupObjectsById(array: any) {
    const groups: any = {};

    for (const obj of array) {
      if (obj.guestId in groups) {
        groups[obj.guestId].data.push(obj);
      } else {
        groups[obj.guestId] = { guestId: obj.guestId, guest: obj.guest, data: [obj] };
      }
    }

    return Object.values(groups);
  }

  get getGroupedServices() {
    const groupedSelectedServices: any = this.groupObjectsById(this.cart.selectedItems);
    return groupedSelectedServices;
  }

  showGuest() {
    this.isGuestTabVisible = !this.isGuestTabVisible;
    this.isSameServices = !this.isSameServices;
    this.sharedService.getLocalStorageItem("isSameService");
    this.sharedService.setLocalStorageItem("isSameService", this.isSameServices.toString());
    if (this.isSameServices) {
      // this.bookingService.ngxUiLoaderService.start();
      this.tabs.guest = 'me';
      const getitemIdOfMe = this.cart.selectedItems.filter((obj: { guestId: any; id: any; }) => !obj.guestId && obj.id);
      const guests = this.cart.guests;

      this.removeItemsFromCartOncheck().then(async () => {
        await this.copyItemsToGuest();
        await this.updateCartDetail();
      });
    }
  }

  async copyItemsToGuest() {

    if (this.isSameServices) {
      const guests = this.cart.guests;
      const mySelectedBaseService = this.getGroupedServices.filter((groupService: any) => groupService.guestId == null)[0]?.data.filter((service: any) => !service.item.optionGroups.length);
      const mySelectedAddons = this.getGroupedServices.filter((groupService: any) => groupService.guestId == null)[0]?.data.filter((service: any) => service.item.optionGroups.length);

      //console.log("my services :: ", mySelectedBaseService, mySelectedAddons, this.getGroupedServices[0]?.guestId);
      this.sharedService.setLocalStorageItem("isSameService", this.isSameServices.toString());

      if (this.getGroupedServices[0]?.guestId == null) {
        //console.log("loop :: ", guests)
        for (const guest of guests) {
          const payload = {
            id: mySelectedBaseService[0]?.item?.id,
            staffId: null,
            guestId: guest.id
          }
          const res: any = await firstValueFrom(this.bookingService.addItemInCart(payload));
          if (!res.errors) {
            let tempCart = this.bookingService.clientCart$.value;
            tempCart.selectedItems = res.data.addCartSelectedBookableItem.cart.selectedItems;
            this.bookingService.clientCart$.next(tempCart);

            const guestBaseService = res.data.addCartSelectedBookableItem.cart.selectedItems.filter((guestSelectedItem: any) => guestSelectedItem.guestId == guest.id)[0];
            if (guestBaseService) {
              for (const myAddon of mySelectedAddons) {
                const addonID = 'urn:blvd:ServiceAddon:' + myAddon.item.id.replace('urn:blvd:Service:', '') + ':' + guestBaseService?.id.replace('urn:blvd:Service:', '');
                const addonPayload = {
                  id: addonID,
                  staffId: null,
                  guestId: guest.id
                }
                const addonRes: any = await firstValueFrom(this.bookingService.addItemInCart(addonPayload));
                if (addonRes) {
                  let tempCart = this.bookingService.clientCart$.value;
                  tempCart.selectedItems = addonRes.data.addCartSelectedBookableItem.cart.selectedItems;
                  this.bookingService.clientCart$.next(tempCart);
                }
              }
            }
          }
        }
      }
    }
  }

  get isHydrafacialPresent() {
    let flag = false;

    if (this.cart.selectedItems.length) {
      const myService = this.cart.selectedItems.filter((item: any) => item.guestId == null && !item.item.optionGroups.length)

      if (myService.length && myService[0].item.name.toLowerCase().includes('hydrafacial') && !this.isSameServices) {
        flag = true;
      }
    }
    return flag;
  }

  canAllowDifferentService() {
    if (this.cart.selectedItems.length) {
      const myService = this.cart.selectedItems.filter((item: any) => item.guestId == null && !item.item.optionGroups.length)

      if (myService.length && myService[0].item.name.toLowerCase().includes('hydrafacial') && !this.isSameServices) {
        const title = 'Please select another service.';
        const message = 'Unfortunately, this service cannot be booked for more than one guest at a time.';
        return this.sharedService.showNotification(title, message);
      }
    }
  }

  setCurrentClient() {
    return this.cart.selectedItems.map((selectedItem: any) => {
      if (selectedItem.guestId == null && selectedItem.addons.length) {
        return 'me'
      } else if (selectedItem.guestId != null && selectedItem.addons.length) {
        return selectedItem.guest;
      }
    }).filter((n: any) => n)[0];
  }

  canShowAddonPopup() {
    let isAddonAdded: boolean = false;
    let serviceWithAddon: boolean = false;

    let flag = false;
    this.cart.selectedItems.forEach((element: any) => {
      const isService = !element.item.optionGroups.length;
      if (!isService) {
        isAddonAdded = true;
      } else if (isService && element.addons.length) {
        serviceWithAddon = true;
      }
    });

    !isAddonAdded && serviceWithAddon ? flag = true : flag = false;
    return flag
  }

  continue() {
    window.scrollTo(0, 0);
    // toggleCart ? this.bookingService.toggleMobileCart() : null;
    let cartMemberCount = this.cart.guests.length + 1;
    if (this.cart.selectedItems.length) {
      if (this.getGroupedServices.length == cartMemberCount) {
        let isAddonsAvailable: boolean = false;
        this.cart.selectedItems.forEach((item: any) => {
          item.addons.length ? isAddonsAvailable = true : null;
        });
        console.log("isAddonsAvailable redirect");
        isAddonsAvailable ? this.router.navigateByUrl('/booking/add-ons') : this.router.navigateByUrl('/booking/schedule');

      } else {
        const title = 'Service not added for member';
        const message = 'Please add the service for all members';
        this.sharedService.showNotification(title, message);
      }
    } else {
      const title = 'Cart is empty';
      const message = 'Add service to continue';
      this.sharedService.showNotification(title, message);
    }
  }
}

function resolve(arg0: boolean) {
  throw new Error('Function not implemented.');
}
function reject() {
  throw new Error('Function not implemented.');
}

