import { Component } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { AuthService } from "../../auth/auth.service";
import { SharedService } from "src/app/shared-component/shared.service";
import { BookingService } from "src/app/booking/booking.service";
import { firstValueFrom } from "rxjs";
import { MembershipService } from "../membership.service";
import { TrackingService } from "../tracking.service";
import { environment } from "src/environments";

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss']
})
export class PaymentComponent {
  activeTab = 0;
  paymentForm!: FormGroup;
  couponForm!: FormGroup;
  availablePaymentMethods: any = [];
  authUser: {
    firstName?: string;
    lastName?: string;
    email?: string;
    mobilePhone?: string;
  } = {};

  cart: any;

  toggleMobileCart: boolean = false;

  constructor(
    public membershipService: MembershipService,
    private formBuilder: FormBuilder,
    public authService: AuthService,
    public sharedService: SharedService,
    public bookingService: BookingService,
    private route: ActivatedRoute,
    private router: Router,
    private trackingService: TrackingService,
  ) {
    this.authService.$AuthUser.subscribe((user: any) => {
      this.authUser = user;
    });
    membershipService.clientCart$.subscribe((cart: any) => {
      if (cart) {
        this.cart = cart;
        this.availablePaymentMethods = cart.availablePaymentMethods;
        setTimeout(() => {
          this._patchCouponForm(cart.offers);
        }, 10);
      } else {
        this.membershipService.getCartDetail();
      }
    })
    setTimeout(() => {
      if (this.cart) {
        this.trackingService.initiateCheckout(this.cart);
      }
    }, 1000);
  }

  async ngOnInit() {
    this._buildForm();
  }

  _buildForm() {
    // PaymentForm
    this.paymentForm = this.formBuilder.group({
      name: ['', Validators.required],
      number: ['', Validators.compose([Validators.required, Validators.maxLength(19), Validators.minLength(9)])],
      cvv: ['', Validators.compose([Validators.required, Validators.maxLength(4), Validators.minLength(3), this.cvvValidator])],
      expiry: ['', Validators.compose([Validators.required, Validators.maxLength(7), Validators.minLength(7), this.expiryValidator])],
      postal_code: ['', Validators.compose([Validators.required, Validators.maxLength(5), Validators.minLength(5)])],
    });

    this.couponForm = this.formBuilder.group({
      promoCode: ['', Validators.required]
    });
  }

  cvvValidator(control: FormControl): { [key: string]: any } | null {
    const valid = /^\d{3,4}$/.test(control.value);
    return valid ? null : { invalidCVV: true };
  }

  expiryValidator(control: FormControl): { [key: string]: any } | null {
    if (control.value) {
      const splitVal = control.value.split('/')
      const month = Number(splitVal[0]);
      const year = Number(splitVal[1]);
      const currentYear = new Date().getFullYear();
      const currentMonth = new Date().getMonth() + 1;
      const valid = year >= currentYear && splitVal[0].length && ((currentYear == year && month >= currentMonth) || (year > currentYear && month <= 12));
      return valid ? null : { invalidExpiry: true };
    } else {
      return { invalidExpiry: true };
    }
  }

  maskExpiryDate(expiryDate: string): string {
    // Extract the month and year from the expiry date string
    const [month, year] = expiryDate.split('/');

    // Replace the year with asterisks
    const maskedYear = year;

    // Concatenate the masked month and year with a forward slash
    const maskedExpiryDate = `${month}/${maskedYear}`;

    return maskedExpiryDate;
  }

  onExpiryDateInput(): void {
    // Get the raw input value from the input field
    const rawValue = this.paymentForm.value.expiry.replace(/\D/g, '');

    // Extract the month and year components from the raw input value
    const month = rawValue.substr(0, 2);
    const year = rawValue.substr(2, 4);

    // Mask the expiry date
    const maskedExpiryDate = this.maskExpiryDate(`${month}/${year}`);

    // Update the input field value with the masked expiry date
    this.paymentForm.patchValue({
      expiry: maskedExpiryDate
    });
  }

  _patchCouponForm(offers: any) {
    if (offers && offers.length) {
      let code = offers[0].code;
      this.couponForm.patchValue({
        promoCode: code
      });
      this.couponForm.disable();
    } else {
      this.couponForm.patchValue({
        promoCode: ''
      });
      this.couponForm.enable();
    }
  }

  autoApplyPromoCode() {
    const coupon = this.sharedService.getLocalStorageItem('coupon');
    if (coupon) {
      this.bookingService.addCartOffer(coupon, this.cart.id).subscribe((res: any) => {
        if (!res.errors && res?.data?.addCartOffer?.offer?.applied) {
          const title = 'Promo Code';
          const message = 'applied successfully';
          //this.sharedService.showNotification(title, message);
          this.membershipService.getCartDetail();
          this.sharedService.removeLocalStorageItem('coupon');
        } else {
          const title = 'Invalid promo code';
          const message = 'Please enter valid promo code';
          this.sharedService.showNotification(title, message);
          !res?.data?.addCartOffer?.offer?.applied ? this.bookingService.removeCartOffer(res?.data?.addCartOffer?.offer?.id, this.cart.id).subscribe() : null;
        }
      })
    }
  }

  applyPromoCode() {
    this.bookingService.ngxUiLoaderService.start();
    const code = this.couponForm.value.promoCode;
    if (this.couponForm.valid && code != '') {
      this.bookingService.addCartOffer(code, this.cart.id).subscribe((res: any) => {
        if (!res.errors && res?.data?.addCartOffer?.offer?.applied) {
          const title = 'Promo Code';
          const message = 'applied successfully';
          this.sharedService.showNotification(title, message);
          this.bookingService.ngxUiLoaderService.stop();
          this.membershipService.getCartDetail();
        } else {
          const title = 'Invalid promo code';
          const message = 'Please enter valid promo code';
          this.sharedService.showNotification(title, message);
          this.bookingService.ngxUiLoaderService.stop();
          !res?.data?.addCartOffer?.offer?.applied ? this.bookingService.removeCartOffer(res?.data?.addCartOffer?.offer?.id, this.cart.id).subscribe() : null;
        }
      })
    } else {
      this.couponForm.markAllAsTouched();
      const title = 'Incorrect promo code';
      const message = 'Please fill the correct promo code';
      this.bookingService.ngxUiLoaderService.stop();
      this.sharedService.showNotification(title, message);
    }
  }

  removePromoCode() {
    this.bookingService.ngxUiLoaderService.start();
    let offerId = this.cart.offers[0].id;
    console.log("this.cart", this.cart.offers[0].id);
    if (offerId) {
      this.bookingService.removeCartOffer(offerId, this.cart.id).subscribe((res: any) => {
        if (!res.errors) {
          const title = 'Promo Code';
          const message = 'removed successfully';
          this.sharedService.showNotification(title, message);
          this.membershipService.getCartDetail();
          this.bookingService.ngxUiLoaderService.stop();
        }else{
          this.bookingService.ngxUiLoaderService.stop();
        }
      })
    }

  }

  navigateToOptions() {
    this.router.navigateByUrl("/membership/selection");
  }
  navigateToAgreement() {
    this.router.navigateByUrl("/membership/agreement");
  }
  navigateToCompleted() {
    this.bookingService.ngxUiLoaderService.stop();
    this.router.navigateByUrl("/membership/completed");
  }

  selectPaymentMethod(card: any) {
    this.membershipService.selectPaymentMethod(card.id).subscribe((res: any) => {
      if (!res.errors) {
        card.active = true;
        this.membershipService.getCartDetail();
      }
    })
  }
  tokenizeRes:any;
  async submitPaymentForm() {
    this.bookingService.ngxUiLoaderService.start();
    if (this.cart?.selectedItems[0]?.selectedPaymentMethod?.id) {
      const res: any = await firstValueFrom(this.bookingService.checkoutCart(this.cart.id));
      if (res.errors) {
        this.bookingService.ngxUiLoaderService.stop();
        return this.sharedService.showNotification("Checkout Error", res.errors[0].message);
      }

      // Save User profile in klavio
      this.addProfileToKlavio();

      // reset payment form
      this.paymentForm.reset();

      // Clear membership cart from local storage
      localStorage.removeItem('membershipCart');

      // Clear membership agreement from local storage
      localStorage.removeItem('membershipAgreement');
      // localStorage.removeItem('billDate');

      this.membershipService.completeCart$.next(this.cart);
      this.bookingService.ngxUiLoaderService.stop();
      // navigate to completed page
      this.navigateToCompleted();
    } else {
      if (this.paymentForm.valid) {
        try {
          const tokenizeRes: any = await firstValueFrom(this.bookingService.tokenizeCard(this.paymentForm.value));
          this.tokenizeRes =await firstValueFrom(this.bookingService.tokenizeCard(this.paymentForm.value));
          console.log("tokenizeRes", tokenizeRes);
        
          if (tokenizeRes.errors) {
            this.bookingService.ngxUiLoaderService.stop();
        
            let message = 'Please add correct card';
            if (tokenizeRes?.errors?.number_last4?.length) {
              message = 'Please check your card number';
              this.paymentForm.controls['number'].setErrors({ invalidCard: true });
            }
        
            console.log("out of IF");
            const title = 'Incorrect payment information';
            return this.sharedService.showNotification(title, message);
          }
        
        } catch (error: any) {
          this.bookingService.ngxUiLoaderService.stop();
        
          // Handle specific 400 error
          if (error.status === 400) {
            const message = 'Invalid card details. Please check and try again.';
            this.paymentForm.controls['number'].setErrors({ invalidCard: true });
            return this.sharedService.showNotification('Payment Error', message);
          }
        
          // Handle other potential errors
          const generalMessage = 'An unexpected error occurred. Please try again.';
          return this.sharedService.showNotification('Error', generalMessage);
        }
        

        const addCardPaymentRes: any = await firstValueFrom(this.bookingService.addCartPaymentMethod(this.tokenizeRes.token, this.cart.id));
        if (addCardPaymentRes.errors) {
          this.bookingService.ngxUiLoaderService.stop();
          return this.sharedService.showNotification("Add Payment Method Error", addCardPaymentRes.errors[0].message);
        }

        const res: any = await firstValueFrom(this.bookingService.checkoutCart(this.cart.id));
        if (res.errors) {
          this.bookingService.ngxUiLoaderService.stop();
          return this.sharedService.showNotification("Checkout Error", res.errors[0].message);
        }

        let agreement: any = localStorage.getItem('membershipAgreement');
        if (agreement) {
          agreement = JSON.parse(agreement);
          const billDate: any = Number(localStorage.getItem('billDate'));
          const agreementPayload = {
            firstName: this.cart.clientInformation.firstName,
            lastName: this.cart.clientInformation.lastName,
            phoneNumber: this.cart.clientInformation.phoneNumber ? this.cart.clientInformation.phoneNumber : '',
            email: this.cart.clientInformation.email,
            membershipChargeDate: billDate,
            facialTypeChoice: this.cart.selectedItems[0].item.name,
            initials: agreement.waiver2.toUpperCase(),
          }



          const agreementRes: any = await firstValueFrom(this.membershipService.generateAgreement(agreementPayload));
          if (agreementRes.errors) {
            this.bookingService.ngxUiLoaderService.stop();
            return this.sharedService.showNotification("Save Agreement Error", res.errors[0].message);
          }
        }

        // Save User profile in klavio
        this.addProfileToKlavio();

        // Clear payment form
        this.paymentForm.reset();

        // Clear membership cart from local storage
        localStorage.removeItem('membershipCart');

        // Clear membership agreement from local storage
        localStorage.removeItem('membershipAgreement');
        // localStorage.removeItem('billDate');

        this.membershipService.completeCart$.next(this.cart);

        // navigate to completed page
        this.navigateToCompleted();

      } else {
        this.paymentForm.markAllAsTouched();
        const title = 'Incorrect payment information';
        const message = 'Please add correct card';
        this.bookingService.ngxUiLoaderService.stop();
        this.sharedService.showNotification(title, message);
      }
    }
  }

  addProfileToKlavio(){
    const email = this.cart.clientInformation.email;
    const firstName = this.cart.clientInformation.firstName;
    const lastName = this.cart.clientInformation.lastName;
    const phone = this.cart.clientInformation.phoneNumber ? this.cart.clientInformation.phoneNumber.substr(2) : this.cart.clientInformation.phoneNumber;
    const address1 = this.cart.location.address.line1;
    const address2 =this.cart.location.address.line2;
    const city = this.cart.location.address.city;
    const state = this.cart.location.address.state;
    const country = this.cart.location.address.country;
    const zip = this.cart.location.address.zip;

    const payload = {
      listSegmentId: environment.klavio_membership_segment_id,
      email:email,
      firstName: firstName,
      lastName: lastName,
      phone: phone,
      address1: address1,
      address2: address2,
      city: city,
      state: state,
      country: country,
      zip: zip,
      membershipStartDate:this.getCurrentDate()
    }; 
    this.bookingService.addToKlaviyo(payload).subscribe((res: any) => { 
      console.log("res",res);
    });
  }
  getCurrentDate() {
    const today = new Date();
    const year = today.getFullYear();
    const month = (today.getMonth() + 1).toString().padStart(2, '0'); // Add leading zero for single digit months
    const day = today.getDate().toString().padStart(2, '0'); // Add leading zero for single digit days
    return `${year}-${month}-${day}`;
}
}
