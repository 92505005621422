import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { MdbModalRef, MdbModalService } from 'mdb-angular-ui-kit/modal';
import { SharedService } from 'src/app/shared-component/shared.service';
import { BookingService } from '../booking.service';/* 
import { ModalAddonsComponent } from './modal-addons/modal-addons.component';
import { ModalIsAddonAddedComponent } from './modal-is-addon-added/modal-is-addon-added.component'; */
import { TrackingService } from '../tracking.service';
import { filter, firstValueFrom, forkJoin } from 'rxjs';
import { NgxUiLoaderService } from 'ngx-ui-loader';
@Component({
  selector: 'app-addons',
  templateUrl: './addons.component.html',
  styleUrl: './addons.component.scss'
})
export class AddonsComponent {

  tabs:{
    timeFilters:string;
    guest:any;
    service:string;
    addons:number
  } = {
    timeFilters: '30',
    guest:'me',
    service:'addon',
    addons:0
  }
  resetServiceTabs:any;
  resetTimeTabs:any;
  serviceFilter:string='Facials 30 Minutes'
  cart:any;
  isSameServices:any = false;
  isGuestTabVisible:boolean = false;
  /* addonModalRef!: MdbModalRef<ModalAddonsComponent> | null;
  isAddonAddedModalRef!: MdbModalRef<ModalIsAddonAddedComponent> | null; */
  modalConfig: any = {
    animation: true,
    backdrop: true,
    containerClass: "right",
    data: {},
    ignoreBackdropClick: false,
    keyboard: true,
    modalClass: "modal-top-right",
  };

  cartSubscription1:any;
  orderingAddons:any=[];
  serviceCalls:any = [];
  processedServiceIDs:any;
  constructor(
    private trackingService: TrackingService, 
    public bookingService: BookingService, 
    private sharedService: SharedService, 
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.processedServiceIDs = new Set();
    bookingService.updateCartDetail();
     this.bookingService.clientCart$.subscribe(async cart => {
      if (cart) {
        this.cart = cart;
        this.loadAddons();
        let offerId = this.cart?.offers[0]?.id;
        if(offerId){
          const resRemoveCartOffer:any = await firstValueFrom(this.bookingService.removeCartOffer(offerId));
          if(!resRemoveCartOffer.errors){
            this.bookingService.getCartDetail();
            this.sharedService.removeLocalStorageItem('promoCode');
          }
        }
      }
    });
  }
  
  loadAddons() {
    this.serviceCalls = []; // Reset serviceCalls on each load

    this.cart?.selectedItems?.forEach((res: { addons: string | any[]; item: { id: string; }; }) => {
      if (res.addons.length) {
        const serviceID = res.item.id.replace('urn:blvd:Service:', '');

        if (!this.processedServiceIDs.has(serviceID)) {
          console.log("Fetching Addons for Service...", serviceID);
          this.serviceCalls.push(this.bookingService.getAddonsOrdering(serviceID));
          this.processedServiceIDs.add(serviceID);
        } else {
          //console.log(`Skipping API call for Service ID ${serviceID} as it's already processed.`);
        }
      }
    });

    if (this.serviceCalls.length) {
      forkJoin(this.serviceCalls).subscribe((responses: any) => {
        this.orderingAddons = responses.reduce((acc: any[], response: { data: { listServiceAddonsByLocation: any[]; }; }) => {
          if (response && response.data && response.data.listServiceAddonsByLocation) {
            return acc.concat(response.data.listServiceAddonsByLocation);
          } else {
            console.warn('Response does not contain valid data.');
            return acc;
          }
        }, []);

      //  console.log("Combined orderingAddons", this.orderingAddons);
        //console.log("Total Addons:", this.orderingAddons.length); // Matches combined length of arrays
      });
    }
  }
  ngOnInit(): void {
      // this.serviceAddRequested();
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.isSameServiceLocal();
    }, 500);
  }

  ngDestroy(){
    this.cartSubscription1.unsubscribe();
   }

  serviceAddRequested(){
    const serviceName = this.sharedService.getLocalStorageItem('serviceName');
    if(serviceName && this.cart && this.cart.id){
      // let cartSubscription2 = this.bookingService.clientCart$;
    
      // cartSubscription2.subscribe((cart)=>{
        // if(cart && cart.id){
          const service = this.sharedService.findServiceIdByName(serviceName, this.cart);
          this.sharedService.removeLocalStorageItem('serviceName');
          // cartSubscription2.unsubscribe();
          service ? this.addService(service) : null;
        // }
      // });
    }
  }

  isSameServiceLocal(){
    let flag = this.sharedService.getLocalStorageItem('isSameService');
    if(!flag || flag == 'true'){
      this.isSameServices = true;
      this.isGuestTabVisible = false;
    }else if(flag == 'false'){
      this.isSameServices = false;
      this.isGuestTabVisible = true;
    }
  }

  showAddons(){
    this.tabs.service = 'addon';
    this.changeServiceTab('addon');
    this.resetServiceTabs = {event:true, currentTab:'addon'};
    setTimeout(() => {
      this.resetServiceTabs = {event:false, currentTab:'addon'};
    }, 1000);
  }
  
  changeServiceTab(ev:any){
    console.log("EV", ev);
    this.tabs.addons = ev;
  }

  resetServicesTab(){
    let currentTab:string = '';
    if(this.tabs.service.toLowerCase().includes('facial')){
      currentTab = 'facial';
      this.resetTimeTab();
    }else if(this.tabs.service == 'addon'){
      currentTab = 'facial';
      this.resetTimeTab();
    }else{
      currentTab = this.tabs.service;
    }
    this.resetServiceTabs = {event:true, currentTab:currentTab};
    setTimeout(() => {
      this.resetServiceTabs = {event:false, currentTab:currentTab};
    }, 1000);
  }

  resetTimeTab(){
    this.resetTimeTabs = '30';
    setTimeout(() => {
      this.resetTimeTabs = null;
    }, 1000);
  }

  changeGuestTab(ev:any){
    if(this.tabs.service == 'addon'){
      let selectedItems;
      if(ev != 'me'){
        selectedItems = this.cart.selectedItems.filter((item:any)=>item.guestId == ev.id);
      }else{
        selectedItems = this.cart.selectedItems.filter((item:any)=>item.guestId == null);
      }
      if(selectedItems && selectedItems.length){
        this.tabs.guest = ev;
      }else{
        this.resetServicesTab();
        const title = 'Service not added';
        const message = 'Please add a service first';
        this.sharedService.showNotification(title, message); 
      }
    }
    this.tabs.guest = ev;
  }

  changeTimeFilterTab(ev:any){
    this.tabs.timeFilters = ev;
    if(ev == '30'){
      this.tabs.service = 'Facials 30 Minutes'
    }else if(ev == '50'){
      this.tabs.service = 'Facials 50 Minutes'
    }
  }

  addService(service:any){
    let selected:boolean = false;
    let canBook:boolean = true;
    let isSameService = this.sharedService.getLocalStorageItem("isSameService");
    this.cart.selectedItems.map((selectedItem:any)=>{
      if(service.name.toLowerCase().includes('hydrafacial') && selectedItem.item.name.toLowerCase().includes('hydrafacial')){
        canBook = false;
        const title = 'Please select another service.';
        const message = 'Unfortunately, this service cannot be booked for more than one guest at a time.';
        return this.sharedService.showNotification(title, message);
      }
      else {
        if(this.tabs.guest != 'me'){
          selectedItem.guestId == this.tabs.guest.id ? selected = true : null;
        }else{
          selectedItem.guestId == null ? selected = true : null;
        }
      }
    })
    if(!selected){
      this.bookingService.ngxUiLoaderService.start();
      const payload = {
        id:service.id,
        staffId:null,
        guestId:this.tabs.guest != 'me' ? this.tabs.guest.id : null
      }
      this.bookingService.addItemInCart(payload).subscribe(async(res:any)=>{
        if(!res.errors){
          service.category = this.tabs.service;
          this.trackingService.addItem(service);
          this.bookingService.updateCartDetail();
          const res:any = await firstValueFrom(this.bookingService.getCartDetail());
          if (res.errors) {
            return this.sharedService.showNotification("Error", "Error getting cart detail.");
          }else{
            this.bookingService.clientCart$.next(res.data.cart);
            this.copyItemsToGuest();
          }
        }else{
          this.sharedService.showNotification('Errors', res.errors[0].message);
          this.bookingService.ngxUiLoaderService.stop();
        }
        this.bookingService.ngxUiLoaderService.stop();
      });
      
    }
    else if(selected  && canBook){
      
      this.bookingService.ngxUiLoaderService.start();
      //this.bookingService.removeItemInCart(addedItem.id).subscribe((res:any)=>{
        this.removeItemsFromCart().then(() => {
         // if(!res.errors){
            const payload = {
              id:service.id,
              staffId:null,
              guestId:this.tabs.guest != 'me' ? this.tabs.guest.id : null
            }
            
            this.bookingService.addItemInCart(payload).subscribe(async(res:any)=>{
              if(!res.errors){
                service.category = this.tabs.service;
                this.trackingService.addItem(service);
                this.bookingService.updateCartDetail();
                const res:any = await firstValueFrom(this.bookingService.getCartDetail());
                if (res.errors) {
                  return this.sharedService.showNotification("Error", "Error getting cart detail.");
                }else{
                  this.bookingService.clientCart$.next(res.data.cart);
                  this.copyItemsToGuest();
                }
  
              }else{
                this.sharedService.showNotification('Errors', res.errors[0].message);
                this.bookingService.ngxUiLoaderService.stop();
              }
              this.bookingService.ngxUiLoaderService.stop();
            });
          //}
          /* else{
            this.sharedService.showNotification('Errors', res.errors[0].message);
          } */
  
        }).catch((error) => {
          this.sharedService.showNotification('Error', error);
        });
        
      //});
    }
    
    else{
      const title = 'Facials, Microchanneling, Chemical Peels and Injectables cannot be performed together';
      const message = 'Please remove existing service from cart';
      this.sharedService.showNotification(title, message);
    }
  
  }
  async removeItemsFromCartOncheck(){
    for (const item of this.cart.selectedItems) {
      try {
        const removalPromises = this.cart.selectedItems.map((item: { id: string; }) => this.bookingService.removeItemInCart(item.id).toPromise());
        await Promise.all(removalPromises);
      } catch (error) {
        this.bookingService.ngxUiLoaderService.stop();
        this.sharedService.showNotification('Error', "Removing item from cart");
      }
    }
  }
  async removeItemsFromCart() {
    let isSameService= this.sharedService.getLocalStorageItem('isSameService');
    if (isSameService == 'false') {
      for (const item of this.cart.selectedItems) {
        try {
          this.bookingService.ngxUiLoaderService.start();
          if (this.tabs.guest == 'me') {
            const idsWithNullGuest = this.cart.selectedItems.filter((service: { guest: null; }) => service.guest === null).map((service: { id: any; }) => service.id);
            const removalPromise = this.bookingService.removeItemInCart(idsWithNullGuest[0]).toPromise();
            await removalPromise;
          } else {
            if (item.guestId === this.tabs.guest.id) {
              const removalPromise = this.bookingService.removeItemInCart(item.id).toPromise();
              await removalPromise;
            }
          }
        } catch (error) {
          this.bookingService.ngxUiLoaderService.stop();
          this.sharedService.showNotification('Error', "Removing item from cart");
        }
      }
    } else {
      for (const item of this.cart.selectedItems) {
        try {
          this.bookingService.ngxUiLoaderService.start();
          const removalPromises = this.cart.selectedItems.map((item: { id: string; }) => this.bookingService.removeItemInCart(item.id).toPromise());
          await Promise.all(removalPromises);
        } catch (error) {
          this.bookingService.ngxUiLoaderService.stop();
          this.sharedService.showNotification('Error', "Removing item from cart");
        }
      }
    }

  }
  
  itemRemovedFromCart(){
    // runs when item is removed from cart
    this.isSameServiceLocal();
    setTimeout(() => {
      this.resetServiceTabs = {event:true, currentTab:'facial'};
    }, 1000);
  }

  // isAnyAddonAdded(){
  //   let flag = false;
  //   let catNames = [];
  //   this.cart.selectedItems.map((selectedItem:any) => {
  //     const catName = this.sharedService.getServiceCategoryName(selectedItem, this.cart.availableCategories);
  //     catName != '' ? catNames.push(catName) : null;
  //     if(selectedItem.selectedOptions.length > 0){
  //       flag = true;
  //     }
  //   });
  //   if(!catNames.length){
  //     flag = true;
  //   }
  //   return flag;
  // }

   getMemberAddedAddonsCount(){
    // let count = 0;
    if(this.cart.guests && this.cart.guests.length){
      this.cart.guests.map((guest:any)=>{
        if(this.cart && this.cart.selectedItems && this.cart.selectedItems.length){
          let selectedItems = this.cart.selectedItems.filter((selectedItem:any)=>{
            return selectedItem.guestId == guest.id && selectedItem.item.optionGroups.length
          });
          guest.addedServiceCount = selectedItems.length;
        }
      });
      let meAsGuest = {"label":"me", "addedServiceCount": 0};
      let selectedItems = this.cart.selectedItems.filter((selectedItem:any)=>{
        return selectedItem.guestId == null && selectedItem.item.optionGroups.length
      });
      meAsGuest.addedServiceCount = selectedItems.length;
      return [...this.cart.guests, meAsGuest];
    }
    return [];
  }

  get getTotalAddedServiceCount(){
    let count = 0;
    if(this.cart && this.cart.selectedItems){
      count = this.cart.selectedItems.length;
      // if(selectedItems && selectedItems.length){
      //   selectedItems.map((selectedItem:any)=>{
      //     ++count;
      //     count = count + selectedItem.selectedOptions.length;
      //   })
      // }
    }
    return count;
  }

  hideGuestTabs(){
    this.isGuestTabVisible = false;
  }

  groupObjectsById(array:any) {
    const groups:any = {};
    
    for (const obj of array) {
      if (obj.guestId in groups) {
        groups[obj.guestId].data.push(obj);
      } else {
        groups[obj.guestId] = { guestId: obj.guestId, guest: obj.guest, data: [obj] };
      }
    }
    
    return Object.values(groups);
  }

  get getGroupedServices(){
    const groupedSelectedServices:any = this.groupObjectsById(this.cart.selectedItems);
    return groupedSelectedServices;
  }

  showGuest() {
    this.isGuestTabVisible = !this.isGuestTabVisible;
    this.isSameServices = !this.isSameServices;
    this.sharedService.getLocalStorageItem("isSameService");
    this.sharedService.setLocalStorageItem("isSameService", this.isSameServices.toString());

    if (this.isSameServices) {
        this.tabs.guest = 'me';

        // Filter All addons
        const selectedAddons = this.cart.selectedItems.filter((item:any)=>{
          return item.item.optionGroups.length
        })

        // Remove Guest Addons
        let promises:any = [];
        selectedAddons.forEach((addon:any) => {
          addon.guestId != null ? promises.push(this.bookingService.removeItemInCart(addon.id)) : null;
        });
        


        // Add Me addons to guests if available
        if(this.cart.guests.length){
          const myAddon = selectedAddons.filter((addon:any) => {
            return addon.guestId == null;
          });
          this.cart.guests.forEach((guest:any) => {
            let guestSelectedItem = this.cart.selectedItems.filter((item:any)=>{
              return guest.id == item.guestId
            })


            if(guestSelectedItem.length && myAddon.length){
              const guestAvailableAddon = guestSelectedItem[0].addons.filter((addon:any)=>{
                return myAddon[0].item.name == addon.name
              })

              if(guestAvailableAddon.length){
                myAddon.forEach((addon:any)=>{
                  const filterGuestAvailableAddon = guestAvailableAddon.filter((guestAddon:any)=> guestAddon.name == addon.item.name);
                  //console.log("filtered addons :: ", filterGuestAvailableAddon, addon);
                  if(filterGuestAvailableAddon.length){
                    let payload = {
                      id: filterGuestAvailableAddon[0].id,
                      staffId: null,
                      guestId: guest.id
                    };
                    promises.push(this.bookingService.addItemInCart(payload))
                  } 
                })
              }
              
            }
          });
          if(promises.length){
            this.bookingService.ngxUiLoaderService.start();
            forkJoin(promises).subscribe((results:any)=>{
              this.bookingService.updateCartDetail();
              this.bookingService.ngxUiLoaderService.stop();
            });
          }
        }
    }
}

copyItemsToGuest() {
    return new Promise<void>((resolve, reject) => {
        this.sharedService.setLocalStorageItem("isSameService", this.isSameServices.toString());

        if (this.isSameServices) {
            const guests = this.cart.guests;
            const mySelectedBaseService = this.getGroupedServices.filter((groupService: any) => groupService.guestId == null)[0]?.data.filter((service: any) => !service.item.optionGroups.length);
            const mySelectedAddons = this.getGroupedServices.filter((groupService: any) => groupService.guestId == null)[0]?.data.filter((service: any) => service.item.optionGroups.length);

            if (this.getGroupedServices[0]?.guestId == null) {
                const guestPromises = guests.map((guest: any) => {
                    const payload = {
                        id: mySelectedBaseService[0]?.item?.id,
                        staffId: null,
                        guestId: guest.id
                    };
                    return this.bookingService.addItemInCart(payload).toPromise().then((res: any) => {
                        if (!res.errors) {
                            // update cart locally
                            let tempCart = this.bookingService.clientCart$.value;
                            tempCart.selectedItems = res.data.addCartSelectedBookableItem.cart.selectedItems;
                            this.bookingService.clientCart$.next(tempCart);

                            const guestBaseService = res.data.addCartSelectedBookableItem.cart.selectedItems.filter((guestSelectedItem: any) => guestSelectedItem.guestId == guest.id)[0];
                            const addonPromises = mySelectedAddons.map((myAddon: any) => {
                                const addonID = 'urn:blvd:ServiceAddon:' + myAddon.item.id.replace('urn:blvd:Service:', '') + ':' + guestBaseService.id.replace('urn:blvd:Service:', '');
                                const addonPayload = {
                                    id: addonID,
                                    staffId: null,
                                    guestId: guest.id
                                };
                                return this.bookingService.addItemInCart(addonPayload).toPromise();
                            });

                            return Promise.all(addonPromises);
                        } else {
                            return Promise.resolve([]); // Return an empty array as a resolved promise
                        }
                    });
                });

                Promise.all(guestPromises).then(() => {
                    resolve();
                }).catch((error) => {
                    reject(error);
                });
            } else {
                resolve(); // Resolve the promise if services are already added for guests
            }
        } else {
            this.isGuestTabVisible = true;
            resolve();
        }
    });
}

  canAllowDifferentService(){
    /* if(!this.getGroupedServices.filter((groupService:any)=>groupService.guestId == null).length){
      const title = 'You have not added any service yet';
      const message = 'Please add a service for yourself first';
      this.sharedService.showNotification(title, message);
    } */
    /* if(this.isSameServices && this.getGroupedServices.length > 1){
      const title = 'Sevice already added for guest';
      const message = 'Please remove the guest services to copy my services';
      this.sharedService.showNotification(title, message);
    }else */ /* if(this.getGroupedServices.length < 1){
      const title = 'You have not added any service yet';
      const message = 'Please add a service for yourself first';
      this.sharedService.showNotification(title, message);
    } */
  }

  setCurrentClient(){
    return this.cart.selectedItems.map((selectedItem:any)=>{
      if(selectedItem.guestId == null && selectedItem.addons.length){
        return 'me'
      }else if(selectedItem.guestId != null && selectedItem.addons.length){
        return selectedItem.guest;
      }
    }).filter((n:any) => n)[0];
  }

  canShowAddonPopup(){
    let isAddonAdded:boolean = false;
    let serviceWithAddon:boolean = false;

    let flag = false;
    this.cart.selectedItems.forEach((element:any) => {
      const isService = !element.item.optionGroups.length;
      if(!isService){
        isAddonAdded = true;
      }else if(isService && element.addons.length){
        serviceWithAddon = true;
      }
    });

    !isAddonAdded && serviceWithAddon ? flag = true : flag = false;
    return flag
  }

  continue(){
    window.scrollTo(0, 0);
    let cartMemberCount = this.cart.guests.length + 1;

    if(this.cart.selectedItems.length){
      if(this.getGroupedServices.length == cartMemberCount){
        this.router.navigateByUrl('/booking/schedule');
      }else{
        const title = 'Service not added for member';
        const message = 'Please add the service for all members';
        this.sharedService.showNotification(title, message);  
      }
    }else{
      const title = 'Cart is empty';
      const message = 'Add service to continue';
      this.sharedService.showNotification(title, message);
    }
  }
}
